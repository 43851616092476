import { Link } from "gatsby"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const PromoBlock = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "Still10A.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section className="u-bg-overlay g-bg-img-hero g-bg-bluegray-opacity-0_3--after" style={{ backgroundImage: `url(${data.placeholderImage.childImageSharp.fluid.src})` }}>
      <div className="container u-bg-overlay__inner text-center g-pt-150 g-pb-70">
        <div className="g-mb-100">
          <span className="d-block g-color-white g-font-size-20 text-uppercase g-letter-spacing-5 mb-4">Shooting Director & DP</span>
          <h1 className="g-color-white g-font-weight-700 g-font-size-20 g-font-size-50--md text-uppercase g-line-height-1_2 g-letter-spacing-10 mb-4">Mikkel Jeppesen</h1>
          <span className="d-block lead g-color-white g-letter-spacing-2">Selected work</span>
        </div>
      </div>
    </section>
  )
}

export default PromoBlock
