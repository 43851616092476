import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import PromoBlock from "../components/promo-block"
import Gallery from "../components/gallery"
import References from "../components/references"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <PromoBlock />
    <Gallery />
    <References />
  </Layout>
)

export default IndexPage
