import { Link } from "gatsby"
import React from "react"

const Gallery = () => (
  <div class="container g-py-100">
    <div className="row">
      <div className="col-md-6 g-mb-30">

        <div class="g-mb-30">
          <div class="d-flex justify-content-start g-brd-bottom g-brd-gray-light-v4 pb-3">
            <div class="d-block">
              <h2 class="h1 g-color-black">Commercials</h2>
            </div>
          </div>
        </div>

        {/* Mobilepay */}
        <div class="embed-responsive embed-responsive-16by9 g-mb-30">
          <iframe src="//player.vimeo.com/video/376525252" width="530" height="300" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
        </div>

        <div class="embed-responsive embed-responsive-16by9 g-mb-30">
          <iframe src="//player.vimeo.com/video/376524780" width="530" height="300" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
        </div>

        <div class="embed-responsive embed-responsive-16by9 g-mb-30">
          <iframe src="//player.vimeo.com/video/376525007" width="530" height="300" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
        </div>

        <div class="embed-responsive embed-responsive-16by9 g-mb-30">
          <iframe src="//player.vimeo.com/video/376524316" width="530" height="300" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
        </div>

        <div class="embed-responsive embed-responsive-16by9 g-mb-30">
          <iframe src="//player.vimeo.com/video/377752807" width="530" height="300" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
        </div>


      </div>
      <div className="col-md-6 g-mb-30">

        <div class="g-mb-30">
          <div class="d-flex justify-content-start g-brd-bottom g-brd-gray-light-v4 pb-3">
            <div class="d-block">
              <h2 class="h1 g-color-black">Documentaries & Fiction</h2>
            </div>
          </div>
        </div>

        {/* Prelude to a fight */}
        <div class="embed-responsive embed-responsive-16by9 g-mb-30">
          <iframe src="//player.vimeo.com/video/376527885" width="530" height="300" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
        </div>

        {/* A childhood in hell */}
        <div class="embed-responsive embed-responsive-16by9 g-mb-30">
          <iframe src="//player.vimeo.com/video/376526590" width="530" height="300" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
        </div>

        {/* Captured by pirates */}
        <div class="embed-responsive embed-responsive-16by9 g-mb-30">
          <iframe src="//player.vimeo.com/video/376525761" width="530" height="300" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
        </div>

        {/* Osman og Jeppe */}
        <div class="embed-responsive embed-responsive-16by9 g-mb-30">
          <iframe src="//player.vimeo.com/video/376526702" width="530" height="300" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
        </div>

        {/* Stemmer på kanten */}
        <div class="embed-responsive embed-responsive-16by9 g-mb-30">
          <iframe src="//player.vimeo.com/video/376528269" width="530" height="300" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
        </div>

      </div>
    </div>
  </div>
)

export default Gallery
