import { Link } from "gatsby"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const References = () => (
  <div className="container pb-5 text-center">
    <span className="d-block g-color-primary g-font-size-17 text-uppercase mb-5">More references</span>
    <h2 className="h1 g-color-black g-font-weight-600 mb-5">Agencies & Broadcasters</h2>
    <p className="lead mb-5">Advice, Bates Y&R, VICE, CPB, Netflix, Ethica, Essencius, Format Agency, MM Pro, 60 minuts, Gobsmack, Winters, Zentropa, Nordisk Film, DR1, DR2, TV2, TV3.</p>

    <h2 className="h1 g-color-black g-font-weight-600 mb-5">Brands</h2>
    <p className="lead mb-5">Sundhedsstyrelsen, Spilmyndighederne, MobilePay, Faxe Kondi, Letz Sushi, Business Danmark, Coca Cola, Center for Ludomani, Fuze Tea, Sundhedsplejen, Politiforbundet, Danske Hospitalsklovne, Suzuki, KL, Rema, Just Eat, Fitness World, Visit Sweden, FOA, DTU, Kriminalforsorgen, Kræftens Bekæmpelse.</p>
  </div>
)

export default References
